import { convertUnderscoresToSpaces } from "@validereinc/utilities";
import PropTypes from "prop-types";
import React from "react";
import { DropdownButton, InputGroup, MenuItem } from "react-bootstrap";
import "./DropdownList.scss";

const DropdownInputRow = ({ options, value, name, disabled, onSelect }) => (
  <div className="dropdownInputRow">
    <div className="title">{convertUnderscoresToSpaces(name)}</div>
    <DropdownButton
      className="button pull-right"
      componentClass={InputGroup.Button}
      id="dropdown"
      title={options?.[value] ? options?.[value] : "Select"}
      onSelect={onSelect}
      pullRight={true}
      disabled={disabled}
    >
      {Object.keys(options).map(function (row, index) {
        return (
          <MenuItem
            key={index}
            eventKey={row}
          >
            {options?.[row]}
          </MenuItem>
        );
      })}
    </DropdownButton>
  </div>
);

const DropdownList = ({
  className,
  list,
  nameFormatter,
  options,
  onSelect,
  disabled,
}) => {
  return (
    <div className="dropdownList">
      <div className={`dropdownList__container ${className || ""}`}>
        {list
          ? Object.keys(list).map((row, index) => {
              const name = nameFormatter ? nameFormatter(row) : row;

              const value = list[row];

              const onDropdownSelect = (selectedValue) => {
                onSelect(row, selectedValue);
              };

              return (
                <DropdownInputRow
                  key={index}
                  name={name}
                  value={value}
                  onSelect={onDropdownSelect}
                  options={options}
                  disabled={disabled}
                />
              );
            })
          : null}
      </div>
    </div>
  );
};

DropdownInputRow.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  options: PropTypes.object,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
};

DropdownList.propTypes = {
  titleCol: PropTypes.number,
  title: PropTypes.string,
  message: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.object,
  list: PropTypes.object,
  onSelect: PropTypes.func,
  nameFormatter: PropTypes.func,
  disabled: PropTypes.bool,
  pullLeft: PropTypes.bool,
};

export default DropdownList;
