import FileService from "#components/Services/FileService";
import { ExceptionUtils } from "#src/utils/exception";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, useAlert } from "@validereinc/common-components";
import { downloadFile } from "@validereinc/utilities";
import classNames from "classnames/bind";
import React from "react";

const cx = classNames.bind({});

/**
 * Shows files uploaded through the Data Platform API as downloadable links.
 * Pre-fetches the file metadata and the file contents when it renders and
 * caches the file metadata for the duration specified by the pre-signed S3 URL or
 * a default of 10 minutes. The file contents however for the specific pre-signed URL are cached
 * forever until the component is unmounted, at which point the file will be
 * garbarge collected within 5 minutes if it isn't rendered again.
 */
const FileDownloadLink = ({
  fileName,
  fileUrl,
  hideIcon,
  prefetch = true,
}: {
  /** the file name */
  fileName: string;
  /** the file URL as specified by our data platform API */
  fileUrl: string;
  /** hide the icon prefixed in the download link ?*/
  hideIcon?: boolean;
  /** prefetch the file contents and cache it? */
  prefetch?: boolean;
}) => {
  const { addAlert } = useAlert();
  const defaultCacheTimeSeconds = 10 * 60;
  const {
    data: fileMeta,
    error: fileMetaError,
    isInitialLoading: isFileMetaInitialLoading,
    isFetching: isFileMetaFetching,
  } = useQuery({
    queryKey: ["files", "meta", fileUrl],
    queryFn: async () => {
      // IMPROVE: move the FileService to the domain package and define new types there
      const data = await FileService.getFileDownloadUrl(fileUrl);

      if (!data) {
        return;
      }

      const {
        data: {
          data: [fileData],
        },
      } = data;

      if (!fileData) {
        throw new Error("Could not extract file data");
      }

      if (fileData.file_scan_status !== "safe") {
        throw new Error("The file download link is not available.");
      }

      // IMPROVE: assertion is a stop-gap until we get time to move the FileService to the domain package and define types there
      const { uri: downloadUrl } = fileData as {
        file_id: string;
        file_scan_status: "safe" | "unsafe";
        uri: string;
      };

      if (!downloadUrl) {
        throw new Error("The file download URL does not exist.");
      }

      // IMPROVE: assertion is a stop-gap until we get time to move the FileService to the domain package and define types there
      return fileData as {
        file_id: string;
        file_scan_status: "safe" | "unsafe";
        uri: string;
      };
    },
    refetchInterval: (data) => {
      if (!data) {
        return false;
      }

      const params = new URLSearchParams(new URL(data.uri).search);

      /** 
        NOTE: the download URL expires and must be refreshed based on the
        window provided in AWS recognized query param in the pre-signed URL
        @see
        {@link https://docs.aws.amazon.com/AmazonS3/latest/API/sigv4-query-string-auth.html}
        the default if this header is not provided is 10mins. (600 seconds)
        */
      return params.has("X-Amz-Expires")
        ? (Number(params.get("X-Amz-Expires")) || defaultCacheTimeSeconds) *
            1000
        : defaultCacheTimeSeconds * 1000;
    },
    retry: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  const { data: fileBlob, error: fileBlobError } = useQuery({
    queryKey: ["files", fileMeta?.file_id, fileMeta?.uri],
    queryFn: () => {
      if (!fileMeta?.uri) {
        return;
      }

      return fetch(fileMeta.uri).then((r) => r.blob());
    },
    enabled: Boolean(fileMeta?.uri && prefetch),
    staleTime: Infinity,
  });
  const downloadFileMutation = useMutation({
    mutationFn: ({
      fileName,
      fileBlob,
    }: {
      fileName: string;
      fileBlob?: Blob;
    }) => {
      downloadFile(fileName, fileBlob);
    },
    onError: (err) => {
      addAlert?.({
        variant: "error",
        message: `${fileName} failed to download.`,
      });
      console.error(err);
    },
  });

  const error = fileMetaError || fileBlobError;

  return (
    <Button
      variant={error ? "text-error" : "text"}
      icon={hideIcon ? "" : error ? "exclamation-triangle" : "paperclip"}
      disabled={isFileMetaFetching}
      isLoading={isFileMetaInitialLoading}
      iconPosition="left"
      className={cx("button")}
      as={"a"}
      title={`Download link for ${fileName}`}
      aria-label={`Click to download ${fileName} to your device.`}
      onClick={() => {
        if (fileMetaError || !fileMeta?.uri) {
          addAlert?.({
            variant: "error",
            message: `Can't download ${fileName}. Unable to fetch the file download link.`,
          });
          ExceptionUtils.reportException(fileMetaError, "warning", {
            hint: `File could not be downloaded from a file download link. (name: ${fileName}, ref: ${fileUrl})`,
          });
          return;
        }

        if (prefetch && fileBlobError) {
          addAlert?.({
            variant: "error",
            message: `Can't download ${fileName}. Unable to fetch the file.`,
          });
          ExceptionUtils.reportException(fileBlobError, "warning", {
            hint: `File could not be downloaded from a file download link. (name: ${fileName}, ref: ${fileUrl})`,
          });
          return;
        }

        if (prefetch) {
          downloadFileMutation.mutate({ fileName, fileBlob });
        } else {
          fetch(fileMeta.uri)
            .then((r) => r.blob())
            .then((blob) =>
              downloadFileMutation.mutate({ fileName, fileBlob: blob })
            )
            .catch((err) => {
              addAlert?.({
                variant: "error",
                message: `Can't download ${fileName}. Unable to fetch the file.`,
              });
              ExceptionUtils.reportException(err, "warning", {
                hint: `File could not be downloaded from a file download link. (name: ${fileName}, ref: ${fileUrl})`,
              });
            });
        }
      }}
    >
      {fileName}
    </Button>
  );
};

export default FileDownloadLink;
