import { FileTypes } from "@validereinc/utilities";

export const getFileIconName = (
  fileType: (typeof FileTypes)[keyof typeof FileTypes]
) => {
  switch (fileType) {
    case FileTypes.WORD_DOCUMENT: {
      return "file-word-o";
    }
    case FileTypes.IMAGE: {
      return "file-image-o";
    }
    case FileTypes.PDF: {
      return "file-pdf-o";
    }
    case FileTypes.POWERPOINT: {
      return "file-powerpoint-o";
    }
    case FileTypes.TEXT_DOCUMENT: {
      return "file-text-o";
    }
    case FileTypes.CSV:
    case FileTypes.EXCEL_DOCUMENT: {
      return "file-excel-o";
    }
    case FileTypes.AUDIO: {
      return "file-audio-o";
    }
    case FileTypes.VIDEO: {
      return "file-video-o";
    }
    default:
      return "file-o";
  }
};
